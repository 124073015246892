
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* adding fonts below */
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratLight.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratRegular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratMedium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/MontserratBold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

html, body, #root{
  height: 100%;
}

ul { 
  list-style-type: disc; 
  list-style-position: inside; 
  margin-left: 15px; 

}
ol { 
  list-style-type: decimal; 
  list-style-position: inside; 
  margin-left: 15px; 

}
ul ul, ol ul { 
  list-style-type: circle; 
  list-style-position: inside; 
  margin-left: 15px; 
}
ol ol, ul ol { 
  list-style-type: lower-latin; 
  list-style-position: inside; 
  margin-left: 15px; 
}

.superFancyList div{
  display: inline-block;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  font-size: 1rem !important;
}
div{
  /* margin: 0 !important; */
}

h1, h2, h3, h4, h5, h6{
  font-family: "Montserrat", sans-serif;
}

.base-input{
  @apply resize-none w-full focus:border-blue-primary py-2 px-4 rounded-md border-2 border-gray-100 focus:outline-none
}
.base-text{
  @apply resize-y w-full focus:border-blue-primary py-4 px-4 rounded-md border-2 border-gray-100 focus:outline-none
}
.page-heading{
  @apply text-2xl lg:text-3xl xl:text-4xl font-bold mb-8 lg:mb-16 text-gray-text
}


.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #043c7b; */
  margin-top: 20px;
  /* color: #fff; */
  color: #043c7b;
  width: 100%;
  border-radius: 20px;
  padding: 10px 50px;
  min-width: 300px;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  @apply lg:w-2/4 
}

.pagination li {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .active{
  border: 1px solid #043c7b;
}

.markup *{
  font-family: "Montserrat", sans-serif !important;
}
.markup {
  @apply space-y-3
}

.filter-input {
  @apply px-4 rounded-md border-1 border-gray-300 focus:border-blue-primary h-10 w-full focus:outline-none text-base resize-none
}
.filter-input:disabled,.input:disabled{
  cursor: not-allowed 
}